import App, { AppInitialProps, AppContext } from 'next/app'
import { Router, withRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { ApolloProvider } from '@apollo/react-hooks'

import { WithApolloProps } from 'next-with-apollo'
import theme from '@go/components/theme'
import withApollo from '@go/graphql/hoc/withApollo'
import { FeedbackProvider } from '@go/components/organisms/Feedback'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { ErrorInfo } from 'react'
import { WithRouterProps } from 'next/dist/client/with-router'
import { getDataFromTree } from '@apollo/react-ssr'
import dynamic from 'next/dynamic'
import { captureException } from '../modules/sentry'

interface State {
  errorEventId?: string
}

const WITHOUT_FOOTER = ['/collaborator/new']

class NextApp extends App<AppInitialProps & WithApolloProps<{}> & WithRouterProps, {}, State> {
  state: State = {
    errorEventId: undefined,
  }

  async getInitialProps({ Component, ctx }: AppContext) {
    try {
      let pageProps = {}

      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx)
      }

      return { pageProps }
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx)
      return {
        hasError: true,
        errorEventId,
      }
    }
  }

  componentDidMount() {
    this.componentDidUpdate()
  }

  componentDidUpdate() {
    const handleRouteChange = () => (window as any).fbq('track', 'PageView')
    Router.events.on('routeChangeComplete', handleRouteChange)
    Router.events.off('routeChangeComplete', handleRouteChange)
  }

  componentDidCatch(error: Error, _: ErrorInfo) {
    const errorEventId = captureException(error)

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId })
  }

  render() {
    const Layout = dynamic(() => import('@go/components/templates/Layout'), { ssr: false })

    const {
      Component,
      pageProps,
      apollo,
      router: { pathname },
    } = this.props

    return (
      <ApolloProvider client={apollo}>
        <ThemeProvider theme={theme}>
          <DefaultSeo
            titleTemplate='%s | Piic[ピーク]'
            openGraph={{
              type: 'website',
              locale: 'ja',
              url: process.env.FRONTEND_HOST,
              site_name: 'Piic[ピーク]',
              images: [
                {
                  url: process.env.FRONTEND_HOST + '/images/ogp.png',
                },
              ],
            }}
            twitter={{
              site: '@Piic69445701',
              cardType: 'summary',
            }}
          />
          <CssBaseline />
          <FeedbackProvider>
            <Layout
              pathname={pathname}
              isLogin={pageProps.isLogin}
              version={process.env.VERSION}
              inMaintenance={process.env.MAINTENANCE === 'true'}
              showFooter={!WITHOUT_FOOTER.includes(pathname)}
            >
              <Component {...pageProps} apollo={apollo} />
            </Layout>
          </FeedbackProvider>
        </ThemeProvider>
      </ApolloProvider>
    )
  }
}

export default withApollo(withRouter(NextApp), { getDataFromTree })

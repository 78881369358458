import * as Sentry from '@sentry/node'
import * as SentryIntegrations from '@sentry/integrations'
import { NextPageContext } from 'next'

const sentryOptions: Sentry.NodeOptions = {
  dsn: process.env.SENTRY_DSN,
  release: `web@${process.env.VERSION}`,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
}

// When we're developing locally
if (process.env.NODE_ENV !== 'production') {
  // Don't actually send the errors to Sentry
  sentryOptions.beforeSend = () => null

  // Instead, dump the errors to the console
  sentryOptions.integrations = [
    new SentryIntegrations.Debug({
      // Trigger DevTools debugger instead of using console.log
      debugger: false,
    }),
  ]
}

Sentry.init(sentryOptions)

export default Sentry

export const captureException = (err: Error, ctx?: NextPageContext) => {
  Sentry.configureScope((scope) => {
    if (err.message) {
      // De-duplication currently doesn't work correctly for SSR / browser errors
      // so we force deduplication by error message if it is present
      scope.setFingerprint([err.message])
    }

    if (ctx) {
      const { req, res, query, pathname } = ctx

      if (res && res.statusCode) {
        scope.setExtra('statusCode', res.statusCode)
      }

      if (typeof window !== 'undefined') {
        scope.setTag('ssr', 'false')
        scope.setExtra('query', query)
        scope.setExtra('pathname', pathname)
      } else {
        scope.setTag('ssr', 'true')
        scope.setExtra('url', req?.url)
        scope.setExtra('method', req?.method)
        scope.setExtra('headers', req?.headers)
      }
    }
  })

  return Sentry.captureException(err)
}
